<template>
  <v-container>
    <h2>[ public route view ]</h2>
    <div>This is a public page that does not require user authentication.</div>
  </v-container>
</template>

<script>
export default {
  name: "Public",
}
</script>